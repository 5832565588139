window.$ = window.jQuery = require('jquery');
import 'jquery-validation'
        require('bootstrap');


// Ajustado para usar script completo do select2 para passar para o select2 todas as classe do select original (bootstrap)
// https://angel-vladov.github.io/select2-theme-bootstrap4/4.0.5.html
require('select2/dist/js/select2.full.min.js');
$.fn.select2.defaults.set( "theme", "bootstrap" );
$.fn.select2.defaults.set("containerCssClass", ':all:');
$('select').select2();

var urlBase;

import html2canvas from 'html2canvas';
window.html2canvas = html2canvas;

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/js/sw.js')
            .then((registration) => {
                reg => console.info('registered sw', reg);
            })
            .catch(err => console.error('error registering sw', err));
}

"use strict";

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

urlBase = $('body').data('base-url');

$('#click-support').on('click', function (e) {
    e.preventDefault();

    var userid = $(this).data('user-id');

    $.ajax({
        url: urlBase + '/api/get-site-support/' + userid,
        type: "GET",
        success: function success(response) {

            Swal.fire({
                title: "Dúvidas ao usar o site?",
                html: response,
                width: 'auto',
                confirmButtonText: 'Ok',
                confirmButtonClass: 'btn',
                showCancelButton: false,
                closeOnConfirm: true,
                closeOnCancel: true,
                icon: 'question',
                background: '#fafafa'
            });
        },
        error: function (err) {
            Toastify({
                text: 'Erro: ' + err.responseJSON.message,
                backgroundColor: "linear-gradient(to right, #FF5D4C, #FF7566)",
                duration: 3000
            }).showToast();
        }
    });
});

$("#sidebarToggle, #sidebarToggleTop").on('click', function (e) {
    $("body").toggleClass("sidebar-toggled");
    $(".sidebar").toggleClass("toggled");

    if ($(".sidebar").hasClass("toggled")) {
        $('.sidebar .collapse').collapse('hide');
    }
    ;
});

$(window).resize(function () {
    if ($(window).width() < 768) {
        $('.sidebar .collapse').collapse('hide');
    }
    ;
});

$('body.fixed-nav .sidebar').on('mousewheel DOMMouseScroll wheel', function (e) {
    if ($(window).width() > 768) {
        var e0 = e.originalEvent,
                delta = e0.wheelDelta || -e0.detail;
        this.scrollTop += (delta < 0 ? 1 : -1) * 30;
        e.preventDefault();
    }
});

$(document).on('scroll', function () {
    var scrollDistance = $(this).scrollTop();
    if (scrollDistance > 100) {
        $('.scroll-to-top').fadeIn();
    } else {
        $('.scroll-to-top').fadeOut();
    }
});

$(document).on('click', 'a.scroll-to-top', function (e) {
    var $anchor = $(this);
    $('html, body').stop().animate({
        scrollTop: ($($anchor.attr('href')).offset().top)
    }, 1000, 'easeInOutExpo');
    e.preventDefault();
});

if ($('#form-login')[0]) {
    $('#form-login').validate({
        rules: {
            username: 'required',
            password: 'required'
        },
        messages: {
            username: {
                required: 'O campo <strong>Nome de Usuário</strong> e obrigatório'
            },
            password: {
                required: 'O campo <strong>Senha</strong> e obrigatório'
            }
        }
    });
}

$('[data-tooltip=tooltip]').tooltip();

$('.colihlink').on('click', function (e) {
    window.location.href = $(this).data('url');
});

// jQuery plugin to prevent double submission of forms
jQuery.fn.preventDoubleSubmission = function() {
  $(this).on('submit',function(e){
    var $form = $(this);

    if ($form.data('submitted') === true) {
      // Previously submitted - don't submit again
      Swal.fire('Dados enviados. Aguarde.');
      e.preventDefault();
    } else {
      // Mark it so that the next submit can be ignored
      $form.data('submitted', true);
    }
  });

  // Keep chainability
  return this;
};
